<template>
  <div>
    <section class="auth-page signup">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-8 mx-auto">
            <h3 class="semiBold-22 text-center auth-title">
              {{ $t("create_new_account") }}
            </h3>
          </div>
          <div class="col-12 col-md-12">
            <b-form class="checkout-form" @submit.prevent="formSubmit">
              <div class="row justify-content-center auth-box-row">
                <div class="col col-md-6 col-lg-4 one">
                  <div class="auth-box">
                    <h4 class="auth-box-title semiBold-15">
                      {{ $t("personal_information") }}
                    </h4>
                    <div class="auth-form-wrap">
                      <div class="auth-form-inner">
                        <b-form-group
                          class="account-inputs"
                          id="first-name-group"
                          :label="$t('form_first_name') + '*'"
                          label-for="first_name"
                          key="first_name"
                        >
                          <b-form-input
                            id="first_name"
                            v-model="form.firstname"
                            type="text"
                            v-on:blur="validateFirstname()"
                            :state="form.firstnameState"
                            aria-describedby="firstname-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="firstname-feedback">
                            {{ form.firstnameError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs"
                          id="last-name-group"
                          :label="$t('form_last_name') + '*'"
                          label-for="last_name"
                          key="last_name"
                        >
                          <b-form-input
                            id="last_name"
                            v-model="form.lastname"
                            type="text"
                            v-on:blur="validateLastname()"
                            :state="form.lastnameState"
                            aria-describedby="lastname-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="lastname-feedback">
                            {{ form.lastnameError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs"
                          id="country-group"
                          :label="$t('form_country') + '*'"
                          label-for="country"
                          key="country"
                        >
                          <b-form-select
                            id="country"
                            v-model="form.address.country_code"
                            :options="countries"
                            class="select"
                          ></b-form-select>
                        </b-form-group>

                        <!-- v-if="!showFullAddress" -->
                        <b-form-group
                          class="account-inputs"
                          id="postcode-group"
                          :label="$t('form_postal_code') + '*'"
                          label-for="postcode"
                        >
                          <b-form-input
                            id="postcode"
                            v-model="form.address.postcode"
                            type="text"
                            :state="form.address.postcodeState"
                            aria-describedby="address.postcode-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="address.postcode-feedback"
                          >
                            {{ form.address.postcodeError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          v-if="showFullAddress"
                          class="account-inputs"
                          id="street-group"
                          :label="$t('form_street') + '*'"
                          label-for="street"
                          key="street"
                        >
                          <b-form-input
                            id="street"
                            v-model="form.address.streetDisplay"
                            type="text"
                            v-on:blur="validateStreet()"
                            :state="form.address.streetDisplayState"
                            aria-describedby="address.streetDisplay-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="address.streetDisplay-feedback"
                          >
                            {{ form.address.streetDisplayError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs"
                          id="house_number-group"
                          :label="$t('form_house_number') + '*'"
                          label-for="house_number"
                          key="house_number"
                        >
                          <b-form-input
                            id="house_number"
                            v-model="form.address.house_number"
                            type="text"
                            v-on:blur="validateHouseNumber()"
                            :state="form.address.house_numberState"
                            aria-describedby="address.house_number-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="address.house_number-feedback"
                          >
                            {{ form.address.house_numberError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs"
                          id="addition-group"
                          :label="$t('form_addition')"
                          label-for="addition"
                          key="addition"
                        >
                          <b-form-input
                            id="addition"
                            v-model="form.address.addition"
                            type="text"
                          ></b-form-input>
                        </b-form-group>
                        <!-- <b-form-group
                          v-if="showFullAddress"
                          class="account-inputs"
                          id="postcode-group1"
                          :label="$t('form_postal_code') + '*'"
                          label-for="postcode1"
                        >
                          <b-form-input
                            id="postcode1"
                            v-model="form.address.postcode"
                            type="text"
                            v-on:blur="validatePostcode()"
                            :state="form.address.postcodeState"
                            aria-describedby="address.postcode1-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="address.postcode1-feedback"
                          >
                            {{ form.address.postcodeError }}
                          </b-form-invalid-feedback>
                        </b-form-group> -->
                        <b-form-group
                          v-if="showFullAddress"
                          class="account-inputs"
                          id="city-group"
                          :label="$t('form_city') + '*'"
                          label-for="city"
                          key="city"
                        >
                          <b-form-input
                            id="city"
                            v-model="form.address.city"
                            type="text"
                            v-on:blur="validateCity()"
                            :state="form.address.cityState"
                            aria-describedby="address.city-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="address.city-feedback">
                            {{ form.address.cityError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          v-if="foundAddress != '' && showFullAddress == false"
                          class="account-inputs"
                          id="add-group"
                          label=" "
                          label-for="add"
                        >
                          <div id="add" class="no-address-match">
                            {{ foundAddress }}
                          </div>
                        </b-form-group>
                        <b-form-group
                          v-if="
                            foundAddresses.length > 0 &&
                            showFullAddress == false
                          "
                          class="account-inputs"
                          id="addm-group"
                          label=" "
                          label-for="addm"
                        >
                          <div id="addm">
                            <b-form-radio
                              style="width: 300px"
                              class="account-radios"
                              v-model="selectedAddress"
                              v-for="(item, index) in foundAddresses"
                              :key="index"
                              name="some-radios"
                              :value="index"
                              >{{ item.text }}</b-form-radio
                            >
                          </div>
                        </b-form-group>
                        <b-form-checkbox
                          v-if="showManual"
                          id="manual"
                          v-model="showFullAddress"
                          name="manual"
                          >{{
                            $t("form_fillout_address_manual")
                          }}</b-form-checkbox
                        >
                        <b-form-group
                          class="account-inputs"
                          id="telephone-group"
                          :label="$t('form_phone') + '*'"
                          label-for="telephone"
                        >
                          <b-form-input
                            id="telephone"
                            v-model="form.address.telephone"
                            type="text"
                            v-on:blur="validatePhone()"
                            :state="form.address.telephoneState"
                            aria-describedby="address.telephone-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="address.telephone-feedback"
                          >
                            {{ form.address.telephoneError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-checkbox
                          id="checkbox-2"
                          name="checkbox-2"
                          label-for="checkbox2"
                          v-model="form.address.default_billing"
                          >{{
                            $t("form_address_also_invoice_address")
                          }}</b-form-checkbox
                        >
                        <b-form-checkbox
                          id="checkbox-1"
                          v-model="isCompany"
                          name="checkbox-1"
                          >{{ $t("form_are_you_a_company") }}</b-form-checkbox
                        >
                        <div v-if="isCompany" class="short-form">
                          <b-form-group
                            class="account-inputs"
                            id="company-group"
                            :label="$t('form_company_name') + '*'"
                            label-for="company"
                            key="company"
                          >
                            <b-form-input
                              id="company"
                              v-model="form.company"
                              type="text"
                              v-on:blur="validateCompany()"
                              :state="form.companyState"
                              aria-describedby="company-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback id="company-feedback">
                              {{ form.companyError }}
                            </b-form-invalid-feedback>
                          </b-form-group>

                          <b-form-group
                            class="account-inputs"
                            id="kvk-number"
                            :label="$t('form_coc_number')"
                            label-for="coc_number"
                            key="coc_number"
                          >
                            <b-form-input
                              id="coc_number"
                              v-model="form.coc_number"
                              type="text"
                              v-on:blur="validateCOCNumber()"
                              :state="form.coc_numberState"
                              aria-describedby="coc_number-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback id="coc_number-feedback">
                              {{ form.coc_numberError }}
                            </b-form-invalid-feedback>
                          </b-form-group>

                          <b-form-group
                            class="account-inputs"
                            id="taxvat-group"
                            :label="$t('form_vat_number')"
                            label-for="taxvat"
                            key="taxvat"
                          >
                            <b-form-input
                              id="taxvat"
                              v-model="form.taxvat"
                              type="text"
                              :state="form.taxvatState"
                              aria-describedby="taxvat-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback id="taxvat-feedback">
                              {{ form.taxvatError }}
                            </b-form-invalid-feedback>
                          </b-form-group>

                          <!-- <b-form-group
                            class="account-inputs"
                            id="taxvat-group"
                            :label="$t('form_vat_number')"
                            label-for="taxvat"
                            key="taxvat"
                          >
                            <b-form-input
                              id="taxvat"
                              v-model="form.taxvat"
                              type="text"
                              :state="form.taxvatState"
                            :disabled="isValidVatNumber === false"
                              aria-describedby="taxvat-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              v-if="showVatMessage != ''"
                              id="taxvat-feedback"
                            >
                              {{ showVatMessage }}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                              v-else
                              id="taxvat-feedback"
                            >
                              {{ form.taxvatError }}
                            </b-form-invalid-feedback>
                          </b-form-group> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-md-6 col-lg-4 two">
                  <div class="auth-box">
                    <h4 class="auth-box-title semiBold-15">
                      {{ $t("login_detail") }}
                    </h4>
                    <div class="auth-form-wrap">
                      <div class="auth-form-inner">
                        <b-form-group
                          class="account-inputs"
                          id="email-group"
                          :label="$t('form_email') + '*'"
                          label-for="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="form.email"
                            type="text"
                            :state="form.emailState"
                            aria-describedby="email-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="email-feedback">
                            {{ form.emailError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs password-input"
                          id="password-group"
                          :label="$t('form_password') + '*'"
                          label-for="password"
                        >
                          <b-form-input
                            id="password"
                            v-model="form.password"
                            type="password"
                            :state="form.passwordState"
                            aria-describedby="password-feedback"
                          ></b-form-input>
                          <client-only>
                            <password-meter :password="form.password" />
                          </client-only>
                          <b-form-invalid-feedback id="password-feedback">
                            {{ form.passwordError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs password-input"
                          id="password_confirm-group"
                          :label="$t('form_password_confirmation') + '*'"
                          label-for="password_confirm"
                        >
                          <b-form-input
                            id="password_confirm"
                            v-model="form.password_confirm"
                            type="password"
                            :state="form.password_confirmState"
                            aria-describedby="password_confirm-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="password_confirm-feedback"
                          >
                            {{ form.password_confirmError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-checkbox
                          id="checkbox-3"
                          name="checkbox-3"
                          label-for="checkbox3"
                          v-model="form.is_subscribed"
                          >{{
                            $t("form_newsletter_subscription")
                          }}</b-form-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-md-8 mx-auto three">
                  <div class="text-center submit-btn">
                    <button type="submit" class="c-btn c-btn-primary">
                      {{ $t("create_account") }}
                    </button>
                  </div>
                </div>
                <div class="col col-md-8 mx-auto four">
                  <div class="go-back-text">
                    <p class="go-back">
                      <b-link @click="$router.back()">{{
                        $t("go_back")
                      }}</b-link>
                    </p>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import CreateAccountThanks from "@/base/core/components/CreateAccountThanks";
import { Logger } from "@storefront/core/lib/logger";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import config from "@config";
//import passwordMeter from "vue-simple-password-meter";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "CreateAccount",
  components: {
    // CreateAccountThanks,
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("create_new_account") },
        { name: "keywords", content: this.$t("create_new_account") },
        { name: "description", content: this.$t("create_new_account") },
      ],
      title: this.$t("create_new_account"),
      link: [
        {
          rel: "canonical",
          href: config.app_url + this.$route.fullPath,
        },
      ],
    };
  },
  mounted() {
    const bcrumb = { current: this.$t("create_new_account"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
  },

  data() {
    return {
      isCompany: false,
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: [],
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
    };
  },
  computed: {
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      Logger.debug(
        "isLoggedIn",
        "createAccount",
        this.$store.getters["user/getIsLoggedIn"]
      )();
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },

    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
      if (this.form.address.postcode.length > 0) {
        this.validatePostcode();
      }
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.validateVAT();
        }
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
  },
  methods: {
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_already_exists");
        return false;
      }
      this.form.emailState = true;
      return true;
    },
    async formSubmit() {
      //TODO Validation
      let formValid = true;
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.form.emailState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "email", formValid)();

      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        Logger.debug("formSubmit", "company", formValid)();
        /* if (this.form.coc_numberState != true) {
          this.validateCOCNumber();
          if (this.coc_numberState != true) {
            formValid = false;
          }
        }
        Logger.debug("formSubmit", "coc_number", formValid)();
        if (this.form.taxvatState != true) {
          this.validateVAT();
          if (this.taxvatState != true) {
            formValid = false;
          }
        }
        Logger.debug("formSubmit", "taxvat", formValid)(); */
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.firstnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "fistname", formValid)();
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.lastnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "lastname", formValid)();
      if (this.form.address.postcodeState != true) {
        this.validatePostcode();
        if (this.form.address.postcodeState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "postcode", formValid)();
      if (this.form.address.house_numberState != true) {
        this.validateHouseNumber();
        if (this.form.address.house_numberState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "house_number", formValid)();
      // if (this.showFullAddress == true) {
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          this.showFullAddress = true;
        }
      }
      Logger.debug("formSubmit", "street", formValid)();

      if (this.form.address.cityState != true) {
        this.validateCity();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "city", formValid)();
      // }
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
        if (this.form.address.telephoneState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "telephone", formValid)();

      if (formValid == true) {
        const street = [this.form.address.streetDisplay];
        if (this.form.address.house_number != "") {
          street.push(this.form.address.house_number);
        }
        if (this.form.address.addition != "") {
          street.push(this.form.address.addition);
        }
        if (this.isCompany != true) {
          this.form.company = "";
          this.form.taxvat = "";
        }

        this.form.address.street = street;

        const retval = await this.$store.dispatch("user/createAccount", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: "account_create",
            text: this.$t("account_created"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/account");
        }
        Logger.debug("create Account", "retval", retval)();
      }

      //this.thanksActive = true;
    },
    validatePassword() {
      Logger.debug(
        "validatePassword",
        "test",
        this.form.password.match(/[a-z]/) != null
      )();
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
        Logger.debug("validatePassword", "createAccount", "length")();
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
        Logger.debug("validatePassword", "createAccount", "lcase")();
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
        Logger.debug("validatePassword", "createAccount", "spchar")();
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
        Logger.debug("validatePassword", "createAccount", "ucase")();
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
        Logger.debug("validatePassword", "createAccount", "decimal")();
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },
    async validateVAT() {
      if (this.isCompany == true) {
        Logger.debug("this.form.taxvat", "validateVAT", this.form.taxvat)();
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            Logger.debug("vatcheck", "validateVat", retval.valid)();
            if (retval.valid == "true") {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
        this.form.taxvatError = "";
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCOCNumber() {
      if (this.isCompany == true) {
        /* if (this.form.coc_number == "") {
          this.form.coc_numberState = false;
          this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
        } */
      } else {
        this.form.coc_numberState = null;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
          //Logger.debug("PostcodeValidation", "length 0", list.length)();
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            Logger.debug("multiple addresses", "item", item)();
            this.foundAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      if (this.form.address.postcode == "") {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      } else {
        this.form.address.postcodeState = true;
      }
    },
  },
};
</script>

<style lang="scss">
.checkout-account {
  h1,
  h2 {
    font-weight: 700;
    letter-spacing: 0.012em;
    margin-bottom: 35px;
  }

  h2 {
    margin-bottom: 26px;
  }

  .checkout-form {
  }

  .first {
  }

  .custom-checkbox {
    padding-left: 42px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .custom-control-label {
    &:before {
      width: 17px;
      height: 16px;
      border-radius: 5px;
      border-width: 1px;
      left: -42px;
      top: 5px;
    }

    &:after {
      top: 8px;
      left: -38px;
      width: 10px;
      height: 6px;
      transform: rotate(-45deg);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
    }
    &:after {
      background-image: none;
    }
  }

  .btn-holder {
    button {
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  .btn-wrap {
    width: 100%;
    padding-top: 12px;

    button {
    }
  }

  button {
    border-radius: 6px !important;
  }
}

.short-form {
  position: relative;
  padding-bottom: 20px;

  .back {
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    line-height: 0;

    &:before {
      content: "";
      border-top: 1px solid $borderColor;
      border-left: 1px solid $borderColor;
      width: 8px;
      height: 8px;
      transform: translateY(-34px) rotate(-45deg);
      position: absolute;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.account-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  font-size: 14px;

  .short-form & {
    margin-bottom: 20px;

    input {
    }
  }

  .first & {
  }

  label {
    min-width: 110px;
    width: 110px;
    font-weight: 400;
    padding-right: 2px;

    .second &,
    .third & {
    }
  }

  > div {
    width: 100%;
  }

  input {
    // border-color: #7f7f7f;
    border-radius: 3px;
  }

  .account-radios label {
    width: 100%;
  }
}
</style>
